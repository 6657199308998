@import "vis-timeline/styles/vis-timeline-graph2d.css";

// ellipsis
.vis-item.vis-range .vis-item-overflow .vis-item-content {
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: block;
}

// smaller font
.vis-timeline {
  font-size: 10pt;
}

// styling & tentative
div.vis-tooltip {
  font-family: inherit;
  background-color: var(--bs-body-bg);
}

.vis-item img {
  margin-bottom: 4px;
  padding: 0 2px;
  opacity: 0.7;
}
.vis-item.tentative img {
  opacity: 0.5;
}
.vis-item.vis-background {
  background-color: rgba(var(--bs-primary-rgb), 0.1);

  &.tentative {
    background-color: rgba(var(--bs-secondary-rgb), 0.1);
  }
}
.vis-item.vis-range {
  border-color: var(--bs-paradiso);
  border-radius: 4px;
  background-color: var(--bs-paradiso-bg);
  border-width: 0.5px;

  &.tentative {
    color: var(--bs-secondary-color);
    border-color: var(--bs-dark-bg-subtle);
    background-color: var(--bs-secondary-bg-subtle);
  }
}
.vis-item.vis-point {
  .vis-dot { border-color: var(--bs-paradiso); }

  &.tentative {
    color: var(--bs-secondary-color);
    .vis-dot {
      border-color: var(--bs-grey);
    }
  }
}

// vis range as header
.vis-group.title {
  border-bottom: none;
  background-color: rgba(var(--bs-body-bg-rgb), 0.5);
}

.vis-item.vis-range {
  &.title, &.title.tentative {
    border-width: 0;
    background-color: inherit;
  }
}
