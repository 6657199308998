// scss/qm-overrides.scss

h1,
.h1 {
  font-weight: bold;
}

h2,
.h2 {
  font-weight: medium;
}

h3,
.h3 {
  font-weight: bold;
}

h4,
.h4 {
  font-weight: 600;
}
