// Extra generic css classes required for the website

.cursor-pointer {
  cursor: pointer;
}


section.bounded.donate,
.row.slice.donate {
  padding-bottom: 10px !important;
}

section.bounded.donate {
  border-bottom: 0 !important;
}

nav.navbar a.nav-link.nav-link-donate {
  @extend .btn;
  @extend .btn-primary;
}

.text-shadow-dark {
  text-shadow: -2px 0 4px #333, 2px 0 4px #333, 0 -2px 4px #333, 0 2px 4px #333;
}

.text-shadow-light {
  text-shadow: -2px 0 4px white, 2px 0 4px white, 0 -2px 4px white, 0 2px 4px white;
}
