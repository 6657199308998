// scss/qm.scss

$min-contrast-ratio:          2.0 !default;

$primary: #80BF26;

// Body x-large

.text-xlarge {
  font-size: 1.6rem;
  line-height: 2.133rem;
}

// Body large

.text-large {
  font-size: 1.333rem;
  line-height: 1.977rem;
}

// Body regular

$body-bg: #f8f8f8 !default;
$body-color: #3f3e47 !default;
$font-size-base: 1rem !default;
$line-height-base: 1.75 !default;
$font-family-sans-serif: "Montserrat", sans-serif !default;

// Body small

.text-small {
  font-size: .8rem;
  line-height: 1.333rem;
}

// Headings
$h1-font-size: 3.333rem !default;
$h2-font-size: 2.533rem !default;
$h3-font-size: 2.133rem !default;
$h4-font-size: 1.75rem !default;

// Navbar

$navbar-brand-font-size: 1.2rem !default;

// Spacer extras

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 12,
);

// text decoration for links
$link-decoration: none;
$link-hover-decoration: underline;
